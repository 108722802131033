var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("the-wrapper-dapp", {
        attrs: {
          "is-new-header": true,
          "dapp-img": _vm.headerImg,
          "banner-text": _vm.header,
          "tab-items": _vm.tabs,
          "active-tab": _vm.activeTab,
          "external-contents": "",
          "on-tab": _vm.tabChanged,
          "valid-networks": _vm.validNetworks,
        },
        scopedSlots: _vm._u([
          {
            key: "tabContent1",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "px-3 py-8 py-md-13 mx-auto",
                    attrs: { "max-width": "700px", color: "transparent" },
                  },
                  [
                    _c("div", { staticClass: "mb-5" }, [
                      _c("div", { staticClass: "mew-heading-2 mb-8 ml-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("ens.search-domain")) + " "),
                      ]),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.findDomain.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pr-0", attrs: { cols: "8" } },
                                [
                                  _c("mew-input", {
                                    staticClass: "mr-3 flex-grow-1",
                                    attrs: {
                                      value: _vm.name,
                                      "has-clear-btn": true,
                                      rules: _vm.rules,
                                      label: _vm.$t("ens.register.domain-name"),
                                      placeholder: _vm.$t("ens.ph.three-char"),
                                      "error-messages": _vm.errorMessages,
                                    },
                                    on: { input: _vm.setName },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pl-0", attrs: { cols: "4" } },
                                [
                                  _c("mew-button", {
                                    attrs: {
                                      loading: _vm.loading,
                                      disabled:
                                        !_vm.name ||
                                        (_vm.name && _vm.name.length < 3) ||
                                        _vm.loading ||
                                        (_vm.name &&
                                          _vm.name.split(".").length > 2),
                                      "has-full-width": true,
                                      "btn-size": "xlarge",
                                      title: _vm.$t("ens.register.name"),
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.findDomain.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "tabContent2",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "px-3 py-8 py-md-13 mx-auto",
                    attrs: { "max-width": "700px", color: "transparent" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between mb-7",
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "mew-heading-2 font-weight-bold" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("ens.my-domains")) + " "
                            ),
                            _c("span", { staticClass: "font-weight-regular" }, [
                              _vm._v("(" + _vm._s(_vm.totalDomains) + ")"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "mew-expand-panel",
                      {
                        staticClass: "my-domains-panel",
                        attrs: {
                          "idx-to-expand": null,
                          "panel-items": _vm.myDomains,
                          "right-action-text": _vm.$t("ens.buy-domain"),
                        },
                        on: { onActionClick: _vm.buyDomain },
                      },
                      [
                        _vm._l(_vm.myDomains, function (domain, idx) {
                          return _c(
                            "template",
                            { slot: "panelBody" + (idx + 1) },
                            [
                              _c(
                                "div",
                                {
                                  key: idx,
                                  class: [
                                    domain.expired ? "expired" : "available",
                                    "ma-3 px-2 px-sm-5",
                                  ],
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "subheader-container" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex align-center",
                                          attrs: { cols: "12", md: "6" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "ens.manage-domains.registrant"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("mew-blockie", {
                                            staticClass: "mx-2",
                                            attrs: {
                                              address: domain.registrarAddress,
                                              width: "25px",
                                              height: "25px",
                                            },
                                          }),
                                          _c("mew-transform-hash", {
                                            attrs: {
                                              hash: domain.registrarAddress,
                                            },
                                          }),
                                          _c("mew-copy", {
                                            staticClass: "ml-2 mew-body",
                                            attrs: {
                                              "copy-value":
                                                domain.registrarAddress,
                                              "is-small": true,
                                            },
                                          }),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "address-link",
                                              attrs: {
                                                href: _vm.network.type.blockExplorerAddr.replace(
                                                  "[[address]]",
                                                  domain.registrarAddress
                                                ),
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "call-made",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v(" mdi-call-made ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex align-center",
                                          attrs: { cols: "12", md: "6" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "ens.manage-domains.controller"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("mew-blockie", {
                                            staticClass: "mx-2",
                                            attrs: {
                                              address: domain.controllerAddress,
                                              width: "25px",
                                              height: "25px",
                                            },
                                          }),
                                          _c("mew-transform-hash", {
                                            attrs: {
                                              hash: domain.controllerAddress,
                                            },
                                          }),
                                          _c("mew-copy", {
                                            staticClass: "ml-2 mew-body",
                                            attrs: {
                                              "copy-value":
                                                domain.controllerAddress,
                                              "is-small": true,
                                            },
                                          }),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "address-link",
                                              attrs: {
                                                href: _vm.network.type.blockExplorerAddr.replace(
                                                  "[[address]]",
                                                  domain.controllerAddress
                                                ),
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "call-made",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v(" mdi-call-made ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between pb-5 pt-8 px-sm-7",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "mew-heading-3" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "ens.manage-domains.what-to-do"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { staticClass: "mx-7" }),
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-2 pa-sm-7" },
                                    _vm._l(
                                      _vm.manageDomainOptions,
                                      function (option, key) {
                                        return _c(
                                          "v-col",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !domain.expired || key === 1,
                                                expression:
                                                  "!domain.expired || key === 1",
                                              },
                                            ],
                                            key: key,
                                            attrs: {
                                              cols: "6",
                                              sm: "6",
                                              md: "4",
                                              lg: "2",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.manage(
                                                      option.type,
                                                      idx
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "greenPrimary",
                                                      "x-large": "",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(option.icon))]
                                                ),
                                                _c("div", [
                                                  _vm._v(_vm._s(option.label)),
                                                ]),
                                                domain.expiration && key === 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "orange--text",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "ens.manage-domains.expire-on",
                                                                  {
                                                                    date: domain.expiration,
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "tabContent3",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "px-3 py-8 py-md-13 mx-auto",
                    attrs: { "max-width": "500px", color: "transparent" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("ens-reverse-lookup", {
                          attrs: {
                            address: _vm.address,
                            "ens-manager": _vm.ensManager,
                            name: _vm.name,
                            "duration-pick": _vm.durationPick,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.onRegister
        ? _c("module-register-domain", {
            ref: "registerDomain",
            attrs: {
              "on-register": _vm.onRegister,
              close: _vm.closeRegister,
              register: _vm.register,
              "not-enough-funds": _vm.notEnoughFunds,
              "loading-commit": _vm.loadingCommit,
              "loading-reg": _vm.loadingReg,
              commited: _vm.committed,
              "minimum-age": _vm.minimumAge,
              commit: _vm.commit,
              "no-funds-for-reg-fees": _vm.noFundsForRegFees,
              "commit-fee-in-eth": _vm.commitFeeInEth,
              "commit-fee-in-wei": _vm.commitFeeInWei,
              "commit-fee-usd": _vm.commitFeeUsd,
              "total-cost-usd": _vm.totalCostUsd,
              "total-cost": _vm.totalCost,
              name: _vm.nameHandler.name,
              "parsed-host-name": _vm.nameHandler.parsedHostName,
              "checking-domain-avail": _vm.loading,
              "generate-key-phrase": _vm.generateKeyPhrase,
              "get-rent-price": _vm.getRentPrice,
              "waiting-for-reg": _vm.waitingForReg,
            },
            on: { getCommitFeeOnly: _vm.getCommitFeeOnly },
          })
        : _vm._e(),
      _c("module-manage-domain", {
        ref: "manageDomain",
        attrs: {
          "setting-ipfs": _vm.settingIpfs,
          "on-manage": _vm.onManage,
          close: _vm.closeManage,
          type: _vm.manageType,
          transfer: _vm.transfer,
          renew: _vm.renew,
          "no-funds-for-renewal-fees": _vm.noFundsForRenewalFees,
          "loading-renew": _vm.loadingRenew,
          "upload-file": _vm.uploadFile,
          "uploaded-hash": _vm.manageDomainHandler.contentHash,
          "set-text-records": _vm.setTextRecords,
          "set-multicoin": _vm.setMulticoin,
          multicoin: _vm.manageDomainHandler.multiCoin,
          "text-records": _vm.manageDomainHandler.txtRecords,
          "set-ipfs": _vm.setIpfs,
          "host-name": _vm.manageDomainHandler.parsedHostName,
          "get-rent-price": _vm.getRentPrice,
          "get-total-renew-fee-only": _vm.getTotalRenewFeeOnly,
          "manage-domain-handler": _vm.manageDomainHandler,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }